@import '../../../scss/functions';
@import '../../../scss/config';
@import '../../../scss/colors';

.placeView {
  h2 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: rem(32px);
    font-weight: 600;
    line-height: rem(32px);
    font-feature-settings: 'cv11' on;
  }

  .list {
    .item {
      display: flex;
      align-items: center;
      border: 1px solid #ADC2D7;
      border-radius: 12px;
      background: #FFFFFF;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      padding: 25px 30px;
      margin-bottom: 20px;
      cursor: pointer;

      h3 {
        color: #2E2E2E;
        align-self: center;
        font-family: 'Poppins', sans-serif;
        font-feature-settings: 'cv11' on;
        font-style: normal;
        font-weight: 600;
        font-size: rem(32px);
        line-height: 32px;
        margin: 0;
        width: 140px;
      }

      p {
        color: #2E2E2E;
        flex: 1;
        font-feature-settings: 'cv11' on;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(32px);
        line-height: 32px;
        margin: 0;
      }
    }
  }
}