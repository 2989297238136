@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.clock {
  align-items: center;
  display: flex;
  gap: 8px;
  background-color: $color__primary;
  // border: 4px solid $color__primary;
  border-radius: $settings__border_radius_pill_full;
  padding: 10px 15px;
  min-width: 150px;
  justify-content: flex-end;

  &.timer {
    background-color: #F5F6FA;
    position: absolute;
    left: 85%;
    top: 40px;
    // left: 50%;
    // top: 30px;
    transform: translateX(-50%);
    
    // &.timerRound {
    //   left: 85%;
    //   top: 40px;
    // }
  }

  span {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: rem(24px);
    font-weight: 600;
    margin: 0;

    &.timer {
      color: #162687;
      min-width: 80px;
      text-align: right;
      font-weight: 700;
      font-variant-numeric: tabular-nums;
    }
  }

  &.blur {
    background: $color__primary_blur; // Make sure this color has an opacity of less than 1
    backdrop-filter: blur(8px); // This be the blur
  }

  .dayStatus {
    .icon {
      width: 32px;
      height: 32px;

      &.light {
        color: $color__accent;
      }

      &.dark {
        color: #ADC2D7;
      }
    }
  }
}