@import '../../../scss/functions';
@import '../../../scss/typography';
@import '../../../scss/colors';

.module {
  flex: 1;

  .module-button {
    background-image: $color__primary_gradient;
    border-radius: 24px;
    color: white;
    display: flex;
    justify-content: center;
    padding: 60px;
    width: 428px;

    .icon {
      height: rem(120px);
      width: rem(135px);
      //vertical-align: -.125em;
      color: white;
    }
  }

  .module-title {
    color: white;
    font-size: rem(36px);
    font-weight: $setting__font_weight_medium;
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
  }

  &.disabled {
    .module-button {
      background-image: none;
      background-color: $color__disabled;

      .icon {
        color: $color__text_disabled;
      }
    }

    .module-title {
      color: $color__text_disabled;
    }
  }
}
