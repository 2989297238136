@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

hr {
  border: 1px solid #ECECEC;
  margin: 0;
}

.top {
  width: 100%;

  .logo {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0;
    width: 100%;
  }

  .toPill {
    display: flex;
    margin-right: 20%;
    // margin-left: 100px;
    // margin-right: 100px;

    .pill {
      background-color: #F5A523;
      // border: 2px solid $color__important;
      border-radius: 56px;
      padding: 10px 20px;

      p {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: rem(32px);
        font-weight: 600;
        margin: 0;

        font-feature-settings: 'cv11' on;
      }
    }
  }

  .shoppCart{
    display: flex;
    gap: 30px;
  }

  .middle {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 30px;
    width: 100%;
  }

  .tripsHeader {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    padding: 20px;
  }

  .rowHeader {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-content: center;
    gap: 60px;
    //background-color: #F5F6FA;

    h2 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 70px;
      margin: 0;

      font-feature-settings: 'cv11' on;

      color: #162687;

    }

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 70px;
      margin: 0;

      font-feature-settings: 'cv11' on;

      color: #000000;
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      font-feature-settings: 'cv11' on;
      color: #2E2E2E;
    }
  }

  .departures {
    padding: 10px 30px;
    margin-top: 10px;
  }

  .layout{
    background: #FFFFFF;
    border-radius: $settings__border_radius;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 30px 30px 20px;

    .acotaciones {
      display: flex;
      flex: 1;
    }

    .bus {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
    }
  }


}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    // justify-content: space-around;
    // align-items:flex-end;
    background-color: #FFFFFF;
    border: 2px solid #ADC2D7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    // position: absolute;
}

.acot:nth-child(3) {
    .item{
        background-color: #969696;
        border: 2px solid #969696;
    }
    
}

.acot:nth-child(4) {
    .item{
        background-color: #2CC682;
        border: 1px solid #22B875;
    }
}

.acot:nth-child(5) {
    .item{
        background-color: #421A96;
        border: 2px solid #421A96;
    }
}

.pages {
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
}

.departuresPage {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  //overflow: scroll;
  justify-content: flex-start;
  flex-direction: row;
  gap: 30px;
  //padding: 30px;
  padding: 0 28px;
}

/*.hImportant {
  color: #FF5500;
}*/