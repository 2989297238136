@import '../scss/config';
@import '../scss/colors';
@import '../scss/functions';

.timersDebug {
  background-color: white;
  border-radius: $settings__border_radius;
  display: inline-block;
  left: 20px;
  padding: 10px;
  position: absolute;
  top: 100px;
  z-index: 9999;

  p {
    margin: 0;
  }
}

.clicker {
  background: transparent;
  height: 70px;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 70px;
  z-index: 9999;
}

.logo {
  padding: 28px 40px 0 40px;
  text-align: left;
  // align-items: center;
  &.roundAlign {
    align-items: center;
  }

  &.small {
    margin-bottom: 0;
  }

  figure {
    display: inline-block;
  }

  .logo-image {
    margin: 0;

    &.logo-image-transpais-dark {
      background-image: url(../assets/images/logo.svg);
      background-repeat: no-repeat;
      height: 45px;
      width: 682px;
    }

    &.logo-image-transpais-light {
      background-image: url(../assets/images/logo-blue-small.svg);
      background-repeat: no-repeat;
      height: 36px;
      width: 545px;
    }

    &.logo-small {
      // align-items: center;
      background-size: 340px 20.7px;
      // margin-top: 10px;
      // background-size: 60%;
    }
  }
}

.section-full {
  height: 100vh;
  width: 100vw;
}

.section-flex {
  display: flex;
  flex-direction: column;
}

.passInput {
  border: 1px solid $color__text_secondary;
  border-radius: $settings__border_radius;
  font-size: rem(28px);
  padding: 20px;
  width: 100%;
}