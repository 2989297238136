@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.trip {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: $settings__border_radius;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  gap: 32px;
  padding: 14px 40px;

  h2 {
    font-weight: 600;
    font-size: rem(70px);
    line-height: 70px;
    font-feature-settings: 'cv11' on;
    color: #162687;
    margin: 0;
  }

  p {
    color: #2E2E2E;
    font-feature-settings: 'cv11' on;
    font-size: rem(32px);
    font-weight: 400;
    line-height: 32px;
    margin: 0;
  }
}

.meta {
  align-items: center;
  background-color: white;
  border-radius: $settings__border_radius;
  display: inline-flex;
  flex: 1;

  .metaItem {
    padding: 30px 80px;
    &.metaRound {
      flex: 1;
      padding: 30px 20px;
    }

    &.disabled {
      p {
        color: #969696;
      }
    }

    &.selected {
      background-color: $color__primary;
      border-radius: $settings__border_radius;
      p {
        color: #FFFFFF;
      }
    }

    h2 {
      color: #2E2E2E;
      font-family: 'Poppins', sans-serif;
      font-feature-settings: 'cv11' on;
      font-style: normal;
      font-weight: 400;
      font-size: rem(28px);
      line-height: 32px;
      margin: 0 0 15px 0;
      text-align: center;
    }

    p {
      color: #162687;
      font-family: 'Poppins', sans-serif;
      font-feature-settings: 'cv11' on;
      font-style: normal;
      font-weight: 600;
      font-size: rem(32px);
      line-height: 32px;
      text-align: center;
      margin: 0;
    }
  }

  .dateSeparator {
    display: block;
    background-color: #ECECEC;
    height: 40px;
    width: 2px;
  }
}