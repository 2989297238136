// Transitions
.cross-fade-leave {
  opacity: 1;
}

.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.cross-fade-enter {
  opacity: 0;
}

.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.cross-fade-height {
  transition: height 0.5s ease-in-out;
}

.folio-enter {
  opacity: 0.01;
  transform: scale(1.4);
}

.folio-enter.folio-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms ease-in, transform 100ms ease-in;
}

.folio-exit {
  opacity: 1;
  transform: scale(1);
}

.folio-exit.folio-exit-active {
  opacity: 0.01;
  transform: scale(1.2);
  transition: opacity 80ms ease-in, transform 80ms ease-in;
}


.notice-enter {
  opacity: 0.01;
}

.notice-enter.notice-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.notice-exit {
  opacity: 1;
  transform: scale(1);
}

.notice-exit.notice-exit-active {
  opacity: 0.01;
  transform: scale(1.2);
  transition: opacity 80ms ease-in, transform 80ms ease-in;
}
