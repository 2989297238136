@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.acotaciones {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;

  h2 {
    color: #2E2E2E;
    font-family: 'Poppins', sans-serif;
    font-feature-settings: 'cv11' on;
    font-style: normal;
    font-weight: 600;
    font-size: rem(32px);
    line-height: 32px;
    margin: 0 0 10px 0;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 20px;

    p {
      color: #2E2E2E;
      font-family: 'Poppins', sans-serif;
      font-feature-settings: 'cv11' on;
      font-style: normal;
      font-weight: 400;
      font-size: rem(20px);
      line-height: 32px;
      margin: 0;
    }
  }
}