@import '../../scss/config';
@import '../../scss/functions';
@import '../../scss/typography';
@import '../../scss/colors';

.bottom {
  background: $color__tertiary;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;

  & > * {
    flex: 1;

    &:last-child {
      text-align: right;
    }
  }

  .middle {
    align-items: flex-end;
    display: flex;
    justify-content: center;
  }
}

.content {
  background-color: #F5F6FA;
  border-radius: $settings__border_radius;
  flex: 1;
  //padding: 30px;
}

.notice {
  text-align: center;

  .pointer {
    height: 24px;
    width: 24px;
  }

  p {
    font-size: rem(26px);
    margin: 0 0 10px;
  }

  &.noticeRight {
    display: flex;
    gap: 10px;
    text-align: left;
  }
}

.top {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

