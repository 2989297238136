@import '../../scss/functions';
@import '../../scss/config';

.printable {
  margin: 3mm !important;
  width: 74mm;

  * {
    font-family: 'Arial', sans-serif;
    font-size: rem(9px);
    font-weight: normal;
    margin: 0;
  }
}

.datetime {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  &.last {
    margin-bottom: 0;
    margin-top: 20px;
  }

  > * {
    flex: 1;
  }

  .date {
    text-align: left;
  }


  .id {
    text-align: center;
  }

  .time {
    text-align: right;
  }
}

.header {
  font-size: rem(11px);
  margin-bottom: 20px;
  text-align: center;

  .company {
    font-size: rem(11px);
    font-weight: 500;
  }

  .rfc {
    font-size: rem(11px);
    font-weight: bold;
  }
  strong {
    font-size: rem(11px);
    font-weight: bold;

  }
}

.disclaimer {
  
  p {
    text-align: justify;
    margin-bottom: 4px;
    font-style: italic;
  }
}

.data {
  font-size: rem(12px);

  strong {
    font-size: rem(14px);
    font-weight: bold;

    &.big {
      font-size: rem(16px);
    }
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.big {
    font-size: rem(16px);

    strong {
      font-size: rem(16px);
    }
  }
}

.userData {
  .data {
    font-size: rem(11px);

    strong {
      font-size: rem(12px);

      &.big {
        font-size: rem(14px);
      }
    }

    .important {
      font-size: rem(12px);
    }
  }
}

.logoService {
  display: flex;
  justify-content: center;
}

.logoGr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    height: 20px;
    width: 50px;
  }
}

.serviceMoney {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;

  strong {
    font-size: rem(11px);
    font-weight: bold;
  }
}

.adress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 20px
  strong {
    font-size: rem(11px);
    font-weight: bold;

  }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  img {
    height: 20px;
    width: 50px;
  }
  strong {
    font-size: rem(11px);
    font-weight: bold;

  }
}

.mb10 {
  margin-bottom: 10px;
}

.spread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.services {
  margin: 10px 0 0;
}

.split {
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;

  > .data {
    flex: 1;
  }

  &.folios {
    margin: 10px 0;

    .data {
      strong {
        font-size: rem(12px);
      }
    }
  }

  &.fixed {
    > .data:last-of-type {
      flex: none;
      //width: 90px;
    }
  }
}

.cut {
  border-top: 1px dashed black;
  margin: 10px 0;

  &.cutMargin {
    margin-top: 30px;
  }
}

.qr {
  h2 {
    font-weight: bold;
    margin-bottom: 10px;
  }
  text-align: center;
}

.paymentWrapper {
  align-items: center;
  display: flex;
  gap: 6px;
  flex-direction: row;
  margin: 10px 0 0;
}

.payment {
  flex: 1;
}
