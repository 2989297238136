.screen-saver {
  &.screen-saver-show {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
  }
}

.screen-saver-enter {
  opacity: 0;
  transform: scale(1.2);
}

.screen-saver-enter-active {
  opacity: 1;
  transform: scale(1.0);

  transition: opacity .25s ease-in-out, transform .3s ease-in-out;
}

.screen-saver-exit {
  opacity: 1;
  transform: scale(1.0);
}

.screen-saver-exit-active {
  opacity: 0;
  transform: scale(1.2);

  transition: opacity .25s ease-in-out, transform .3s ease-in-out;
}
