@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.component {
  //background-color: red;
  flex: 1;
  //display: flex;
  overflow: hidden;
  //padding: 0 28px;
  position: relative;
  //width: 100%;

  .scrollBtn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .icons {
    color: #162687;
    height: 56px;
    width: 56px;
  }
}

.carrousel {
  //background-color: blue;
  overflow-x: auto;
  //padding: 0 28px;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  //display: flex;
  //flex-wrap: nowrap;
  //overflow: scroll;
  //justify-content: space-between;
  //border-radius: 16px;
  //background-color: #FFFFFF;
  //height: 140px;
  //width: 1233px;
  // border: 2px solid #d0b428ee;
  //overflow: hidden;
}