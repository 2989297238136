@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.top {
  .logo {
    text-align: left;
    width: 100%;
  }

  .thanks {
    flex: 1;
    text-align: center;

    h1 {
      color: $color__done;
      font-size: rem(54px);
      margin: 10px 0 0;
    }

    p {
      font-size: rem(26px);
      margin: 0 0 20px;
    }
  }

  .middle {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;

    .details {
      background-color: $color__tertiary;
      border-radius: $settings__border_radius;
      flex: 1;
      height: 658px;
      margin: 0 40px 0;
      position: relative;

      .scrollTop {
        top: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }

      .scrollBottom {
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 50%);
      }

      .tickets {
        max-height: 658px;
        overflow-y: auto;
        padding: 20px 0 1px;
        position: relative;

        /* hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .resume {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-right: 40px;
      width: 500px;

      .receipt {
        background-color: $color__tertiary;
        border-radius: $settings__border_radius;
        padding: 20px;
        text-align: center;

        h1 {
          color: $color__text_primary;
          font-size: rem(28px);
          margin: 0 0 10px;
          text-transform: uppercase;
        }

        p {
          color: $color__text_primary;
        }

        .disclaimer {
          font-size: rem(14px);
          margin-top: 30px;
          text-align: left;
        }

        .multiGroup {
          display: flex;
          gap: 20px;
          flex-direction: row;

          > * {
            flex: 1;
          }
        }

        .group {
          p {
            font-size: rem(14px);
            font-weight: 500;
            margin: 20px 0 14px;
            text-transform: uppercase;
          }

          .data {
            background: white;
            border-radius: $settings__border_radius;
            color: $color__text_primary;
            display: block;
            font-size: rem(18px);
            font-weight: 600;
            padding: 16px;
          }
        }
      }
    }
  }
}
