@import '../../../scss/functions';
@import '../../../scss/config';
@import '../../../scss/colors';

.calendarView {
  // flex: 1;
  //overflow-y: scroll;
  background-color: #FFFFFF;
  padding: 0 30px 30px;
  text-align: center;
  width: 750px;
}

.inputs {
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 40px;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .input {
    background: #FAFAFA;
    border: 1px solid #969696;
    border-radius: 12px;
    padding: 26px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: rem(32px);
    font-weight: 400;
    line-height: rem(32px);
    font-feature-settings: 'cv11' on;
    width: 100%;
    position: relative;
    text-align: center;

    .cleanDate {
      cursor: pointer;
      display: inline-flex;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px;

      svg {
        color: #2E2E2E;
        height: 32px;
        width: 32px;
      }
    }
  }

  label {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: rem(32px);
    line-height: 32px;
    text-align: center;
    font-feature-settings: 'cv11' on;
    color: #2E2E2E;
  }
}

.calendarMonth {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    font-feature-settings: 'cv11' on;
    color: #2E2E2E;
  }

  .calendarDays {
    display: grid;
    grid-template-columns: 54px 54px 54px 54px 54px 54px 54px;
    column-gap: 30px;
    row-gap: 15px;

    .calendarDay {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        //background-color: blue;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: rem(32px);
        line-height: 32px;
        text-align: center;
        font-feature-settings: 'cv11' on;
        color: #2E2E2E;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        height: 48px;
        width: 48px;
      }
      
      &.empty {
        cursor: default;
      }

      &.selectedDeparture {
        span {
          background-color: #2CC682;
          border-radius: 100%;
          color: #FFFFFF;
        }
      }

      &.selectedReturning {
        span {
          background-color: #2CC682;
          //border-radius: 100%;
          color: #FFFFFF;
        }
      }

      &.disabled {
        span {
          background-color: transparent;
          //border-radius: 100%;
          color: #ECECEC;
        }
      }
    }
  }
}