@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.top {
  width: 100%;

  .logo {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0;
    width: 100%;
  }

  .middle {
    align-items: flex-start;
    display: flex;
    gap: 30px;
    padding: 30px;
    width: 100%;

    .saleCard {
      background-color: $color__tertiary;
      border-radius: $settings__border_radius;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 20px;
      position: relative;

      &.cardDepartures {
        background-color: white;
        max-width: 868px;
      }

      & > h2 {
        color: #2E2E2E;
        font-weight: 400;
        text-align: center;

        .hImportant {
          font-weight: 600;
        }
      }
    }

    .searchFormFields {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 30px;
    }
  }
}

.departures {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.hImportant {
  color: #FF5500;
}

.spinnerLoad {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 40.5%;
  position: absolute;
  top: 45%;

  p {
    color: #162687;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    font-feature-settings: 'cv11' on;
    margin: 0;
  }

  svg {
    width: 60px;
    height: 60px;
    color: #162687;
  }
}

footer {
  gap: 34px;

  .left {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    max-width: 868px;
  }
}