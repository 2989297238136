@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.list {
    height: 380px;
    margin-top: 10px;
    overflow: scroll;
    position: relative;
    
    .item {
      display: flex;
      justify-content: flex-end;
      border: 1px solid #ADC2D7;
      border-radius: 6px;
      background: #FFFFFF;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      height: 76px;
      padding: 25px 25px;

      p {
        // flex: 1;
        color: #C8C8C8 !important;
        font-feature-settings: 'cv11' on;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: rem(24px);
        line-height: 24px;
        // margin: 0;
        text-align: right;

        span {
          color: #FF5500;
          font-feature-settings: 'cv11' on;
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: rem(24px);
        }

        &.mail {
          color: #C8C8C8;
        }
      }
    }
    
  }