@import './functions';
@import './config';

.transpais-button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  & > span {
    border-radius: $settings__border_radius;
    display: inline-flex;
    //min-width: 590px;
    overflow: hidden;

    .icon-wrapper {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      padding: (12px * $settings__scale);
      transition: background-color 0.25s ease-in-out;

      .icon {
        height: rem(16px * $settings__scale);
        width: rem(16px * $settings__scale);
        color: white;
        transition: color 0.25s ease-in-out;
      }
    }

    &[class*="transpais-button-style-"] {
      transition: background-color 0.25s ease-in-out;

      &[class*="destructive"] {
        background-color: $color__destructive;

        .icon-wrapper {
          background-color: $color__destructive_secondary;
        }
      }

      &[class*="neutral"] {
        background-color: $color__neutral;

        .icon-wrapper {
          background-color: $color__neutral_secondary;
        }
      }

      &[class*="positive"] {
        background-color: $color__call_to_action;

        .icon-wrapper {
          background-color: $color__call_to_action_secondary;
        }
      }

      &[class*="default"] {
        border: 4px solid $color__call_to_action;

        .label {
          color: $color__call_to_action;
        }
        // .icon-wrapper {
        //   background-color: $color__call_to_action_secondary;
        // }
      }
      &[class*="sencillo"] {
        .label {
          color: $color__neutral;
        }
      }
      &[class*="redondo"] {
        background-color: $color__neutral;
        border-radius: 50px;
        
        .label {
          // color: $color__neutral;
        }
      }
    }

    .label {
      color: white;
      flex: 1;
      font-weight: 600;
      font-size: 16px * $settings__scale;
      padding: (12px * $settings__scale) (24px * $settings__scale);
      text-align: center;
      transition: color 0.25s ease-in-out;
    }
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;

    span[class*="transpais-button-style-"] {
      background-color: $color__disabled;

      .icon-wrapper {
        background-color: $color__disabled;

        .icon {
          color: $color__text_disabled;
        }
      }
    }

    .label {
      color: $color__text_disabled;
    }
  }
}
