@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Gotham-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../assets/fonts/Gotham-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/Gotham-Bold.otf') format('opentype');
}

$setting__font: 'Gotham', sans-serif;
$setting__font_weight_light: 300;
$setting__font_weight_regular: 400;
$setting__font_weight_medium: 600;
$setting__font_weight_bold: 700;
