@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.actionFormField {
  background-color: #FFFFFF;
  border: 1px solid #ADC2D7;
  border-radius: $settings__border_radius;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  gap: 20px;

  &.disabled {
    box-shadow: none;
    cursor: not-allowed;

    .actionFormFieldIcon, .actionFormFieldLabel {
      color: $color__disabled;

      h2, p, .icon {
        color: $color__disabled;
      }
    }

    .actionFormFieldValue {
      background-color: $color__disabled;
    }
  }

  .actionFormFieldIcon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 20px 0 0;

    .icon {
      width: 48px;
      height: 48px;
      color: #162687;
    }
  }

  .actionFormFieldLabel {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    h2 {
      color: #2E2E2E;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      font-feature-settings: 'cv11' on;
      margin: 0;
    }

    p {
      color: #FF5500;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      font-feature-settings: 'cv11' on;
      margin: 0;
    }
  }

  .actionFormFieldValue {
    align-items: center;
    border-radius: $settings__border_radius;
    display: flex;
    justify-content: center;
    padding: 35px;
    width: 348px;
    background: $color__accent;
    text-transform: capitalize;

    h2 {
      color: #FFFFFF;
      font-family: 'Poppins', sans-serif;
      font-feature-settings: 'cv11' on;
      font-style: normal;
      font-weight: 600;
      font-size: 70px;
      line-height: 70px;
      margin: 0;
    }
  }
}