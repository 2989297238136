@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.cart {
  align-items: center;
  display: flex;
  gap: 15px;
  background-color: #F5F6FA;;
  // border: 4px solid $color__primary;
  border-radius: $settings__border_radius_pill_full;
  padding: 10px 15px;

  p {
    color: #2E2E2E;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: rem(28px);
    font-weight: 700;
    margin: 0;
  }

  .icon {
    width: 32px;
    height: 32px;
    color: $color__accent;
  }

//   &.blur {
//     background: $color__primary_blur; // Make sure this color has an opacity of less than 1
//     backdrop-filter: blur(8px); // This be the blur
//   }

  .dayStatus {
    // .icon {
    //   width: 32px;
    //   height: 32px;

    //   &.light {
    //     color: $color__accent;
    //   }

    //   &.dark {
    //     color: #ADC2D7;
    //   }
    // }
  }
}