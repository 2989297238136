$color__primary: #1D2A7B;
$color__primary_blur: rgba(15, 25, 85, 0.6);
$color__secondary: #4F5CAD;
$color__icon: #6E80A9;
$color__icon_light: #96A8D1;
$color__text_primary: #212121;
$color__text_primary_important: #1D2A7B;
$color__text_secondary: #6A6A6A;

$color__tertiary: #F2F2F2;
$color__important: #FF5F00;
$color__accent: #F5A523;

$color__disabled: #D4D4D4;
$color__text_disabled: #FFFFFF;

$color__done: #2CC682;

$color__notice_background: #F4F7FC;

$color__primary_gradient: linear-gradient(180deg, #1F3599 0%, #172B85 100%);

