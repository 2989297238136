@import 'colors';
@import 'typography';

* {
  box-sizing: border-box;
  font-family: $setting__font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

a {
  text-decoration: none;
}

html {
  touch-action:none;
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

p, span {
  color: $color__text_primary;
}

.page-enter {
  //left: 0;
  height: 100vh;
  opacity: 0;
  //top: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity .25s ease-in-out, transform .25s ease-in-out;
}

.page-exit {
  opacity: 1;
  //top: 0;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(1);
  transition: opacity .25s ease-in-out, transform .25s ease-in-out;
}

// Modal

.swal-overlay {
  background-color: rgba(20, 39, 133, 0.6);
}

.swal-modal {
  border-radius: 9px;
}

.swal-title {
  color: $color__text_primary;
  font-size: 36px;
  font-weight: 600;
}

.swal-text {
  color: $color__text_primary;
  font-size: 22px;
  text-align: center;
}

.swal-button {
  font-size: 22px;
  background-color: $color__primary;
  border-radius: 9px;
  padding: 10px 23px;

  &:not([disabled]):hover {
    background-color: $color__primary;
  }
}

.hg-theme-default {
  padding: 10px;

  .hg-row {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .hg-button:not(:last-child) {
      margin-right: 10px;
    }
  }

  .hg-button {
    .key-icon {
      color: $color__primary;
      height: 46px;
      width: 46px;
    }

    &.hg-standardBtn, &.hg-functionBtn {
      color: $color__primary;
      font-size: 46px;
      height: 80px;
      padding: 10px;
      width: 80px;
    }

    &.hg-button-esc, &.hg-button-bksp, &.hg-button-lock, &.hg-button-shift, &.hg-button-at {
      span {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      }
    }

    &.hg-button-at, &.hg-button-dotcom {
      font-size: 24px;
      font-weight: 600;
      max-width: 82px;
    }
  }
}