@import '../../scss/functions';
@import '../../scss/typography';
@import '../../scss/colors';

.sectionDashboard {
  .logo {
    text-align: center;
    position: relative;
    
    .clock {
      position: absolute;
      top: 20px;
      right: 10px;
    }
  }

  .imageWrapper {
    display: flex;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;

    .image {
      flex: 1;
      margin: 0;
      padding: 0;
    }

    .imageDashboardBusBackground {
      background-image: url(../../assets/images/quiosco-bg-01.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .sectionDashboardContent {
    height: 100vh;
    justify-content: center;

    .modules {
      display: flex;
      align-items: center;
      align-self: center;
      flex: 1;
      justify-content: flex-end;
      flex-direction: column;
      width: 100%;

      .modulesContent {
        background-color: rgba(0, 51, 102, 0.3);
        border-radius: 54px;
        display: flex;
        gap: 30px;
        justify-content: center;
        margin-bottom: 50px;
        padding: 30px;
      }

      .content {
        display:flex;
        gap: 10px;
      }
      .departures {
        display: flex;
        flex-direction: column;
        // width: 100%;
        background-color: #0F195599;
        border-radius: 34px;
        padding: 0 21px 0 21px;
        p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
          text-align: center;
          font-feature-settings: 'cv11' on;
          color: #FFFFFF;

          &.corridastxt {
            font-size: 18px;
          }
        }
      }
    }

    .footer {
      //background-color: rgba(0, 51, 102, 0.3);
      //border-top: 1px solid #003366;
      color: white;
      display: flex;
      flex-direction: row;
      font-size: rem(12px);
      font-weight: $setting__font_weight_medium;
      justify-content: space-between;
      padding: 10px;
    }
  }
}
