/*----- Colors -----*/
$color__call_to_action: #64C387;
$color__call_to_action_secondary: #50AF73;

$color__neutral: #1D2A7B;
$color__neutral_secondary: #091667;

$color__destructive: #F44141;
$color__destructive_secondary: #d03737;

$color__disabled: #D4D4D4;
$color__text_disabled: #FFFFFF;

$color__tertiary: #F4F7FC;

/*----- Settings -----*/
$settings__scale: 2.25;
$settings__border_radius: 4px * $settings__scale;
