@import './functions';
@import './config';

.transpais-icon-button {
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  text-decoration: none;

  &[class*="transpais-icon-button-style-"] {
    border-radius: 100%;
    display: inline-flex;
    //min-width: 590px;
    overflow: hidden;
    transition: background-color 0.25s ease-in-out;

    .icon-wrapper {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      padding: (12px * $settings__scale);
      transition: background-color 0.25s ease-in-out;

      .icon {
        color: white;
        height: rem(16px * $settings__scale);
        width: rem(16px * $settings__scale);
        transition: color 0.25s ease-in-out;
      }
    }

    &[class*="clear"] {
      background-color: transparent;

      .icon-wrapper {
        .icon {
          color: inherit;
          height: rem(32px * $settings__scale);
          width: rem(32px * $settings__scale);
        }
      }
    }

    &[class*="destructive"] {
      background-color: $color__destructive;
    }

    &[class*="neutral"] {
      background-color: $color__neutral;
    }

    &[class*="positive"] {
      background-color: $color__call_to_action;
    }
  }
}
