@import './functions';
@import './config';

.loader {
  .loader-wrapper {
    align-items: center;
    //background-color: $color__tertiary;
    border-radius: 4px * $settings__scale;
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    img {
      margin-bottom: 20px;
    }
  }

  &.loader-show {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;

    .loader-wrapper {
      display: flex;
    }
  }
}

.loader-enter {
  opacity: 0;
  transform: scale(1.2);
}

.loader-enter-active {
  opacity: 1;
  transform: scale(1.0);

  transition: opacity .25s ease-in-out, transform .3s ease-in-out;
}

.loader-exit {
  opacity: 1;
  transform: scale(1.0);
}

.loader-exit-active {
  opacity: 0;
  transform: scale(1.2);

  transition: opacity .25s ease-in-out, transform .3s ease-in-out;
}
