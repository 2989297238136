@import '../../scss/config';
@import '../../scss/functions';
@import '../../scss/typography';
@import '../../scss/colors';

.instructionsLoop {
  height: 290px;
  width: 290px;
}

.top {
  .logo {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 30px;
    width: 100%;

    .timers {
      display: flex;
      justify-content: center;
      gap: 675px;
    }
  }

  .middle {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 50px;
    width: 100%;

    .formWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #F5F6FA;
      border-radius: 12px;
      gap: 40px;
      height: 554px;
      position: relative;
      padding: 30px;


      p {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        font-feature-settings: 'cv11' on;
        margin: 10px 0 0 0;
        text-align: left;
      }

      .formContent {
        width: 600px;
      }

      .form {
        display: flex;
        flex-direction: column;
        text-align: center;

        p {
          font-weight: 300;
          font-size: 26px;

        }

        h2 {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          margin: 15px;

          &.labelFolio {
            margin-top: 30px;
          }
        }

        input {
          width: 100%;
          background: #FAFAFA;
          mix-blend-mode: normal;
          border: 1px solid #969696;
          border-radius: 12px;
          padding: 10px;

          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-size: rem(32px);
          font-weight: 400;
          line-height: rem(32px);
          font-feature-settings: 'cv11' on;
          height: 82px;
        }
      }
    }
  }
}

.importantText {
  font-weight: 600;
  text-align: center;
}

//   .keyboardWrapper {
//     bottom: 30px;
//     display: none;
//     position: fixed;
//     left: 30px;
//     //transform: translateX(-50%);
//     z-index: 9999;

//     &.keyboardShown {
//       display: block;
//     }
// }
////////

.keyboardWrapper {
  display: none;
  top: 82%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  &.keyboardShown {
    display: block;

    &.reserva {
      top: 93%;
    }

    &.pin {
      top: 59%;
    }
  }
}
