@import '../../../scss/functions';
@import '../../../scss/config';
@import '../../../scss/colors';

.view {
    background-color: #FFFFFF;
    position: relative;

    .footer {
        text-align: center;
        background-color: #F5F6FA;
    }

    .container {
        padding: 0 30px 10px;

        .search {
            display: flex;
            justify-content: center;
            input {
                width: 680px;
                background: #FAFAFA;
                mix-blend-mode: normal;
                border: 1px solid #969696;
                border-radius: 12px;
                padding: 26px;

                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-size: rem(32px);
                font-weight: 400;
                line-height: rem(32px);
                font-feature-settings: 'cv11' on;
            }
          
        }
    }
}

.keyboardWrapper {
    bottom: 30px;
    display: none;
    position: fixed;
    left: 30px;
    //transform: translateX(-50%);
    z-index: 9999;

    &.keyboardShown {
        display: block;
    }
}