@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.modalView {
  background-color: rgba(15, 25, 85, 0.7);
  display: flex;
  height: 100vh;
  justify-content: flex-end;
  left: 0;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 99;

  &.hidden {
    background-color: transparent;
    pointer-events: none;

    .modalViewContainer {
      display: none;
    }
  }

  .modalViewContainer {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }

  .modalViewHeader{
    //display: flex;
    //flex-direction: row;
    //justify-content:center;
    padding-top: 30px;
    position: relative;

    h1 {
      //display: flex;
      //flex: 1;
      //justify-content: center;
      text-align: center;
    }

    .close {
      align-items: center;
      background-color: white;
      border-radius: 100%;
      cursor: pointer;
      color: #2E2E2E;
      display: flex;
      height: 44px;
      justify-content: center;
      position: fixed;
      right: 20px;
      top: 20px;
      width: 44px;
    }
  }
}