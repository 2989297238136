@import '../../scss/config';
@import '../../scss/functions';
@import '../../scss/typography';
@import '../../scss/colors';

.instructionsLoop {
  height: 290px;
  width: 290px;
}

.logo {
  text-align: center;
  margin-bottom: 28px;
}

.formWrapper {
  align-items: center;
  background-color: $color__tertiary;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 50px;
  position: relative;
  text-align: center;
  transition: height 0.25s ease-in-out;

  .action {
    margin-top: 20px;
  }

  .folio {
    background-color: white;
    border-radius: $settings__border_radius;
    min-height: 113.5px;
    padding: 27px 40px;

    .item {
      color: $color__text_primary_important;
      display: inline-block;
      font-size: 46px;
      font-weight: $setting__font_weight_medium;
      overflow: hidden;
      padding: 0 6px;
    }
  }

  .instructions {
    padding: 20px 0;
    width: 600px;

    h1 {
      margin: 0;
    }
  }

  .keyboardWrapper {
    display: none;
    top: 79%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    &.keyboardShown {
      display: block;
    }
  }

  .keyboardIconWrapper {
    color: $color__primary;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: relative;

    .keyboardNotice {
      align-items: center;
      display: flex;
      left: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      p {
        color: $color__text_primary;
        line-height: 1;
        margin: 0;
        white-space: nowrap;
      }

      .pill {
        background-color: white;
        border: 1px solid $color__text_primary;
        border-radius: $settings__border_radius_pill;
        padding: 10px 15px;
      }
    }
  }

  .loadingWrapper {
    display: flex;
    justify-content: center;
  }
}
