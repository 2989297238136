@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';
@import './config';

.seat {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 64px;
    height: 64px;
    // justify-content: space-around;
    // align-items:flex-end;
    background-color: #FFFFFF;
    border: 2px solid #ADC2D7;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: absolute;

    &.preview {
        cursor: default;
        position: relative;
        width: 42px;
        height: 42px;
    }

    &.disabled {
        background-color: #969696;
        cursor: not-allowed;

        span {
            color: #6B6B6B;
            position: absolute;
        }
        .icon {
            align-self: flex-end;
            color: #C8C8C8;
            padding-left: 30px;
            width: 28px;
            height: 28px;
        }
    }

    &.placeholder {
        background-color: #f5f5f5;
        border: none;
        cursor: default;
        box-shadow: none;
        span {
            color: #969696;
        }
    }

    &.selected {
        background-color: #2CC682;
        border: 1px solid #22B875;

        span {
            color: white;
        }
    }

    span {
        color: #2E2E2E;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(28px);
        font-feature-settings: 'cv11' on;
        line-height: rem(28px);
        margin: 0;
        position: absolute;
    }

    .icons {
        // flex-direction: column;
        align-self: flex-end;
        color: $color__neutral;
        padding-left: 30px;
        height: 21px;
        width: 21px;
    }
}