@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.ticket {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0 20px 20px;

  &.selectable {
    cursor: pointer;
  }

  &.selected {
    > div {
      border: 2px solid $color__primary;
    }
  }

  &.unselected {
    > div {
      border: 2px solid white;

      p, span, h3 {
        color: $color__disabled !important;
      }
    }

    .icon {
      color: $color__disabled !important;
    }

    .selectWrapper {
      h3, .icon {
        color: $color__primary !important;
      }
    }

    .tag {
      background-color: $color__disabled !important;
    }
  }

  p {
    margin: 0;
  }

  .info {
    align-items: center;
    background-color: white;
    border-radius: $settings__border_radius;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 30px;

    &.small {
      padding: 14px;

      .data {
        p {
          margin: 0;
        }
      }
    }

    .seat {
      margin-left: 10px;
      margin-right: 30px;
      text-align: center;

      h3 {
        color: $color__text_primary;
        font-size: rem(10px);
        font-weight: 400;
        line-height: 18px;
        margin: 0 0 6px;
        text-transform: uppercase;
      }

      p {
        color: $color__text_primary;
        font-size: rem(26px);
        font-weight: 600;
        line-height: 26px;
      }
    }

    .data {
      .name {
        font-size: rem(26px);
        font-weight: 600;
        margin: 0 0 6px 0;
      }

      .tag {
        background-color: $color__primary;
        border-radius: $settings__border_radius_pill;
        color: white !important;
        font-weight: 600 !important;
        margin-left: 10px;
        padding: 3px 14px;

        &.return {
          background-color: $color__secondary;
        }
      }

      .type {
        align-items: baseline;
        display: flex;
        margin: 0;

        span {
          color: $color__text_secondary;
          font-size: rem(18px);
          font-weight: 400;
          line-height: 18px;
        }

        .category {
          margin-right: 10px;

          &.icon {
            color: $color__text_secondary;
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }

  .precio {
    background-color: white;
    border-radius: $settings__border_radius;
    display: flex;
    padding: 16px; // 30px;
    //vertical-align: middle;
    align-items: flex-start;

    p {
      flex: 1;
      font-size: rem(36px);
      font-weight: 600;
      //margin-right: 20px;
      padding-top: 24px;
      text-align: left;
      width: 184px;
    }
  }

  .print {
    align-items: center;
    background-color: white;
    border-radius: $settings__border_radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 14px 0 0 0;
    width: 78px;

    span {
      color: $color__primary;
      margin-top: 2px;
      font-size: rem(12px);
      font-weight: 600;
    }

    .icon {
      color: $color__primary;
      height: 36px;
      width: 36px;

      &.done {
        color: $color__done;
      }
    }
  }

  .servicio {
    align-items: center;
    background-color: $color__primary;
    border-radius: $settings__border_radius_pill;
    display: flex;
    padding: 6px 10px;
    margin: 0 10px;
    gap: 10px;

    span {
      font-size: rem(16px) !important;
      color: white !important;
      font-weight: 600 !important;
    }

    .icon {
      color: white;
      width: 22px;
      height: 22px;
    }
  }

  .selectWrapper {
    text-align: center;

    h3 {
      color: $color__primary;
      font-size: rem(10px);
      font-weight: 700;
      line-height: 18px;
      margin: 0 0 6px;
      text-transform: uppercase;
    }

    .icon {
      color: $color__done;
      height: 36px;
      width: 36px;
    }
  }
}
