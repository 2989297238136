@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.departure {
  align-self: flex-start;
  background: #FFFFFF;
  border: 1px solid #ADC2D7;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: $settings__border_radius;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;

  hr {
    border: 1px solid #ECECEC;
    margin: 0;
  }

  .amenities {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 20px;

    .icons {
      color: #2E2E2E;
    }
  }

  &.nopad {
    gap: 0;
    padding: 0;
  }

  &.departureFull {
    gap: 0;
    padding: 0;
    flex-basis: calc(33.333333% - 20px);
  }

  & > div {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .data {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;

    & > div {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
  }

  .pad {
    padding: 20px;
  }

  .details {
    color: #2E2E2E;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    font-feature-settings: 'cv11' on;
  }

  .departureInfo {
    display: flex;
    justify-content: space-between;

    h3 {
      color: $color__accent;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: rem(32px);
      font-feature-settings: 'cv11' on;
      line-height: rem(32px);
      margin: 0;

      &.right {
        text-align: right;
      }
    }

    p {
      color: #162687;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: rem(44px);
      font-feature-settings: 'cv11' on;
      line-height: rem(44px);
      margin: 0;

      span {
        font-family: 'Poppins', sans-serif;
        font-size: rem(28px);
        color: #162687;
        text-transform: uppercase;

        &.alt {
          font-family: 'Roboto', sans-serif;
          margin-right: 5px;
        }
      }
    }
  }

  .departureExtra {

    &.inner {
      margin-top: 20px;
    }

    h3 {
      color: #969696;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: rem(22px);
      font-feature-settings: 'cv11' on;
      line-height: 22px;
      margin: 0;
    }

    p {
      color: #F5A523;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: rem(24px);
      font-feature-settings: 'cv11' on;
      line-height: 24px;
      margin: 0;
    }
  }

  .departureMeta {
    align-items: center;

    .departureBrand {
      margin: 0;
      padding: 0;
    }

    .trip {
      color: #162687;
      font-family: 'Poppins', sans-serif;
      font-size: rem(28px);
      font-weight: 600;
      margin: 0;
    }
  }

  .withAmenities {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}