@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.top {
  .logo {
    margin-bottom: 28px;
    position: relative;
    width: 100%;

    .toPay {
      align-items: flex-end;
      bottom: 0;
      display: flex;
      left: 50%;
      margin-left: 100px;
      position: absolute;

      .pointer {
        color: $color__important;
        height: rem(14px * $settings__scale);
        margin-right: 20px;
        width: rem(14px * $settings__scale);
        transition: color 0.25s ease-in-out;
      }

      .pill {
        background-color: white;
        border: 2px solid $color__important;
        border-radius: $settings__border_radius_pill;
        padding: 10px 15px;

        p {
          color: $color__important;
          font-size: rem(20px);
          font-weight: 600;
          margin: 0;
        }
      }
    }
  }

  .middle {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;

    .details {
      background-color: $color__tertiary;
      border-radius: $settings__border_radius;
      flex: 1;
      height: 778px;
      //overflow: auto;
      margin: 0 20px 0 40px;
      position: relative;

      .scrollTop {
        top: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }

      .scrollBottom {
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 50%);
      }

      .tickets {
        max-height: 778px;
        overflow-y: auto;
        padding: 20px 0 1px;
        position: relative;

        /* hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .resume {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-right: 40px;
      width: 600px;

      .card {
        background-color: $color__tertiary;
        border-radius: $settings__border_radius;
        padding: 20px;
        text-align: center;

        > * {
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .cardsLogos {
          display: flex;
          justify-content: center;
          gap: 30px;
        }
      }

      .cols {
        display: flex;
        gap: 20px;
        position: relative;
      }

      .redondo {
        align-items: center;
        background-color: $color__important;
        border-radius: 100%;
        bottom: 4px;
        display: flex;
        left: 50%;
        padding: 12px;
        position: absolute;
        transform: translateX(-50%);
        vertical-align: middle;

        .icon {
          color: white;
          height: 36px;
          width: 36px;
        }
      }

      .data {
        flex: 1;

        &.half {
          flex-basis: 50%;
          min-width: 0;
        }

        h2 {
          color: $color__text_secondary;
          font-weight: 600;
          font-size: rem(20px);
          margin: 0 0 20px;
        }

        .value {
          background: white;
          border-radius: $settings__border_radius;
          color: $color__text_primary;
          display: block;
          font-size: rem(24px);
          font-weight: 600;
          overflow: hidden;
          padding: 20px;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .important {
        background-color: $color__primary;
        border-radius: $settings__border_radius;
        padding: 20px;
        text-align: center;

        p {
          color: white;
          font-size: rem(22px);
          font-weight: 400;
          margin: 0 0 20px 0;
        }

        .email {
          background: white;
          border-radius: $settings__border_radius;
          color: $color__important;
          display: block;
          font-size: rem(24px);
          font-weight: 600;
          padding: 20px;
        }
      }

      .total {
        h2 {
          color: $color__text_primary;
          font-weight: 600;
          font-size: rem(26px);
          margin: 0 0 10px;
        }

        .precio {
          background: white;
          border-radius: $settings__border_radius;
          color: $color__text_primary_important;
          display: block;
          font-size: rem(48px);
          font-weight: 600;
          padding: 14px;
          text-align: center;
        }
      }
    }
  }
}

.importantText {
  font-weight: 600;
  text-align: center;
}