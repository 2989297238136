@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';


.busLayout {
    background: url('../../assets/images/buslayout.svg') top left no-repeat;
    position: relative;
    width: 1508px;
    height: 398px;
    padding: 20px 20px 20px 110px;

    > div {
        position: relative;
    }

    &.piso0 {
        .volante {
            background: url('../../assets/images/volante.svg') top left no-repeat;
            position: absolute;
            top: 318px;
            left: 60px;
            width: 54px;
            height: 54px;
        }
    }
}
