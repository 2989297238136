@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.top {
  .logo {
    text-align: left;
    width: 100%;
  }

  .thanks {
    flex: 1;
    text-align: center;

    h1 {
      color: $color__done;
      font-size: rem(54px);
      margin: 10px 0 0;
    }

    p {
      font-size: rem(26px);
      margin: 0 0 20px;
    }
  }

  .middle {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;

    .details {
      display: flex;
      // border: 2px solid #cc971b;
      // background-color: $color__tertiary;
      background-color: #6B6B6B;
      border-radius: $settings__border_radius;
      // border: 2px solid #1bcc94;
      flex: 1;
      max-width: 1195;
      max-height: 710px;
      // justify-content:center;
      margin: 0 40px 0;
      position: relative;
      // position:static;
      // margin-bottom: 15px;
      padding: 20px;
      padding-bottom: 0px;
      gap: 20px;
      // overflow-x: hidden;
      overflow-y: scroll;
      // overflow: hidden;
      // overflow: auto;

      .scrollTop {
        top: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }

      .scrollBottom {
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 50%);
      }

      .ticketContainer {
        // border: 2px solid #cc971b;
        background-color: white;
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        display: block;
        // border: 2px solid #1bcc94;
        max-height: 710px;
        // position: absolute;
        // border: 2px solid #1b24cc;
      }

      .tickets {
        max-height: 695px;
        max-width: 310px;
        overflow-x: auto;
        background-color: white;
        // width: 350px;
        // overflow-y: scroll;
        overflow-x: scroll;
        padding: 20px 0 1px;
        // position: relative;
        // border: 2px solid #1bcc94;
        /* hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

      }

      .footerTicketContainer {
        background-color: #16268782;
      }
      
      .printButton {
        // position: relative;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;

        background-color: white;
        border-radius: 6px;
        // border-color: #16268782;
        cursor: pointer;
        display: flex;
        border: 1px solid $color__primary;
        // border: 2px solid #1bcc94;
        // flex-direction: column;
        justify-content: center;
        // align-items: center;
        // padding: 10px 0 0 0;
        padding: 10px;
        width: 129px;
        height: 43px;
        gap: 10px;
        margin-left: 0px;
        margin-right: 0px;
        z-index: 3;
    
        span {
          color: $color__primary;
          // margin-top: 2px;
          font-size: rem(16px);
          font-weight: 600;
        }
    
        .icon {
          color: $color__primary;
          height: 26px;
          width: 26px;
    
          &.done {
            color: $color__done;
          }
        }
      }
      
      &::after {
        content: "";
        position: fixed;
        // position: absolute;
        bottom: 175px;
        margin-left: 40px;
        margin-right: 580px;
        left: 0;
        right: 0;
        height: 65px;
        // width: 100%;
        // width: auto;
        // background-color: #16268782;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(8px);
        border-bottom-left-radius: $settings__border_radius;
        border-bottom-right-radius: $settings__border_radius;
        pointer-events: none; 
        z-index: 2;
      }
    }

    .resume {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-right: 40px;
      width: 500px;

      .receipt {
        background-color: $color__tertiary;
        border-radius: $settings__border_radius;
        padding: 20px;
        text-align: center;

        h1 {
          color: $color__text_primary;
          font-size: rem(28px);
          margin: 0 0 10px;
          text-transform: uppercase;
        }

        p {
          color: $color__text_primary;
        }

        .disclaimer {
          font-size: rem(14px);
          margin-top: 30px;
          text-align: left;
        }

        .multiGroup {
          display: flex;
          gap: 20px;
          flex-direction: row;

          > * {
            flex: 1;
          }
        }

        .group {
          p {
            font-size: rem(14px);
            font-weight: 500;
            margin: 20px 0 14px;
            text-transform: uppercase;
          }

          .data {
            background: white;
            border-radius: $settings__border_radius;
            color: $color__text_primary;
            display: block;
            font-size: rem(18px);
            font-weight: 600;
            padding: 16px;
          }
        }
      }
    }
  }
}
