@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

hr {
  border: 1px solid #ECECEC;
  margin: 0;
}

.top {
  width: 100%;

  .logo {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0;
    width: 100%;
  }

  .toPill {
    display: flex;
    margin-right: 20%;

    .pill {
      background-color: #F5A523;
      border-radius: 56px;
      padding: 5px 20px;

      p {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: rem(32px);
        font-weight: 600;
        margin: 0;

        font-feature-settings: 'cv11' on;
      }
    }
  }

  .middle {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 30px;
    width: 100%;
  }

  .tripsHeader {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    padding: 20px;
  }

  .rowHeader {
    align-content: center;
    display: flex;
    flex: 1;
    gap: 60px;
    justify-content: space-between;

    h2 {
      color: #162687;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 70px;
      margin: 0;
      font-feature-settings: 'cv11' on;
    }

    h1 {
      color: #000000;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 70px;
      font-feature-settings: 'cv11' on;
      margin: 0;
    }

    p {
      color: #2E2E2E;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      font-feature-settings: 'cv11' on;
      line-height: 32px;
      text-align: center;
    }
  }

  .datesContent {
    align-items: center;
    background-color: white;
    border-radius: $settings__border_radius;
    display: inline-flex;

    .dateItem {
      padding: 30px;

      &.disabled {
        p {
          color: #969696;
        }
      }

      &.selected {
        background-color: $color__primary;
        border-radius: $settings__border_radius;

        p {
          color: #FFFFFF;
        }
      }

      p {
        color: $color__primary;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: rem(32px);
        font-feature-settings: 'cv11' on;
        line-height: 32px;
        margin: 0;
        text-align: center;
        white-space: nowrap;
      }

      .date {
        font-weight: 400;
        margin-bottom: 15px;
      }

      .cost {
        font-weight: 600;
      }
    }

    .dateSeparator {
      display: block;
      background-color: #ECECEC;
      height: 40px;
      width: 2px;
    }
  }

  .departures {
    padding: 0 2px;
    margin-top: 20px;
  }

  .departuresEmpty {
    background: white url('../../assets/images/empty.svg') no-repeat center center;
    background-size: contain;
    border-radius: 14px;
    margin: 20px;
    height: 574px;
    position: relative;

    .instructions {
      align-items: center;
      bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 50%;
      margin: 0;
      position: absolute;
      transform: translateX(-50%);
    }

    h4 {
      color: #969696;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: rem(22px);
      font-feature-settings: 'cv11' on;
      line-height: 22px;
    }

    .retry {
      backdrop-filter: blur(5px);
      border-radius: $settings__border_radius_pill;
      border: 3px solid #162687;
      cursor: pointer;
      color: #162687;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: rem(32px);
      line-height: 32px;
      padding: 20px 40px;
    }
  }
}

.pages {
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
}

.departuresPage {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-start;
  padding: 0 28px;
  width: 100%;
}
