@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.segmentedControl {
  display: flex;
  gap: 40px;
  justify-content: center;

  .segmentedControlItem {
    background-color: white;
    border: 4px solid $color__primary;
    border-radius: 100px;
    color: $color__primary;
    cursor: pointer;
    font-size: rem(32px);
    font-weight: 600;
    padding: 16px 26px;

    &.selected {
      background-color: $color__primary;
      color: white;
    }
  }
}